export const RichContentMigration = {
  CustomTab: 'specs.groups.CustomTabContentType',
  GroupDescription: 'specs.groups.GroupDescriptionContentType',
  GroupFeed: 'specs.groups.GroupFeedContentType',
  CentralFeed: 'specs.groups.CentralFeedContentType',
} as const;

export type RichContentMigrationSpec = keyof typeof RichContentMigration;

export const serverlessBaseURL = '_api/social-groups-serverless';
