import type { RequestOptions } from '@wix/http-client';

import type {
  ICreateGroupRequest,
  IGroupRequest,
  IDeclineGroupRequest,
  IApproveGroupRequest,
  IApproveGroupResponse,
  IListGroupRequest,
  IListGroupResponse,
} from '@wix/social-groups-serverless/dist/group-requests/types';

import { serverlessBaseURL } from '../constants';

export function create(
  params: ICreateGroupRequest,
): RequestOptions<IGroupRequest> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/group-requests/create`,
  };
}

export function remove(id: string): RequestOptions<void> {
  return {
    method: 'DELETE',
    url: `/${serverlessBaseURL}/group/group-requests/${id}`,
  };
}

export function decline(params: IDeclineGroupRequest): RequestOptions<void> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/group-requests/decline`,
  };
}

export function approve(
  params: IApproveGroupRequest,
): RequestOptions<IApproveGroupResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/group-requests/approve`,
  };
}

export function list(
  params: IListGroupRequest,
): RequestOptions<IListGroupResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/group-requests/list`,
  };
}
