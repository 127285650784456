import type { RequestOptions } from '@wix/http-client';
import {
  QueryEventsRequest,
  QueryEventsResponse,
} from '@wix/ambassador-events-v3-event/types';
import {
  IExtendedEvent,
  ICreateGroupMembersEventRequest,
  IUpdateGroupMembersEventRequest,
} from '@wix/social-groups-serverless/dist/events/types';
import {
  DeleteGroupMembersEventResponse,
  UpdateGroupMembersEventResponse,
} from '@wix/ambassador-socialgroups-v1-group-members-event/types';
import { serverlessBaseURL } from '../constants';

export function getConnectedEvents(
  groupId: string,
  params: QueryEventsRequest = {},
): RequestOptions<IExtendedEvent[]> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/${groupId}/events/get-connected-events`,
  };
}

export function query(
  params: QueryEventsRequest,
): RequestOptions<QueryEventsResponse> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/events/query`,
  };
}

export function create(
  params: ICreateGroupMembersEventRequest,
): RequestOptions<IExtendedEvent> {
  return {
    data: params,
    method: 'POST',
    url: `/${serverlessBaseURL}/group/members-event/create`,
  };
}

export function remove({
  eventId,
}: {
  eventId: string;
}): RequestOptions<DeleteGroupMembersEventResponse> {
  return {
    method: 'DELETE',
    url: `/${serverlessBaseURL}/group/members-event/${eventId}`,
  };
}

export function update(
  params: IUpdateGroupMembersEventRequest,
): RequestOptions<UpdateGroupMembersEventResponse> {
  return {
    data: params,
    method: 'PATCH',
    url: `/${serverlessBaseURL}/group/members-event/update`,
  };
}
